import 'react-popper-tooltip/dist/styles.css'

import { FC, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { Config, usePopperTooltip } from 'react-popper-tooltip'

import { Arrow, Popper } from './styles'

interface Props extends Config {
  content: string | ReactElement
  width?: string | number
}

export const Tooltip: FC<Props> = ({ children, content, width, ...config }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
      trigger: 'hover',
      offset: [0, 8],
      ...config,
    })

  return (
    <>
      <div data-testid='tooltip-trigger' ref={el => setTriggerRef(el)}>
        {children}
      </div>
      {visible &&
        document.body &&
        createPortal(
          <Popper
            width={width}
            ref={el => setTooltipRef(el)}
            {...getTooltipProps({ className: 'tooltip-container' })}
          >
            <Arrow {...getArrowProps({ className: 'tooltip-arrow' })} />
            {content}
          </Popper>,
          document.body
        )}
    </>
  )
}
