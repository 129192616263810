import { Station } from 'interfaces/Station'
import { useMemo, useRef, useState } from 'react'
import { createFilter, InputActionMeta, SingleValue } from 'react-select'
import { useDebounce, useToggle, useUpdateEffect } from 'react-use'

// Hack to ignore using bad path to type from react-select
// 'react-select/dist/declarations/src/filters'
interface FilterOptionOption<Option> {
  readonly label: string
  readonly value: string
  readonly data: Option
}

export interface UseSelectParams {
  stations: Array<Station>
  initial?: Station | null
  changeCallback?: () => void
  exclude?: Station | null
}

export const useSelect = ({ stations, changeCallback, exclude, initial }: UseSelectParams) => {
  const getInitialValue = () => {
    if (!initial) return null
    return stations.find(station => station.uic === initial.uic) || null
  }

  const ref = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<Station | null>(getInitialValue())
  const [inputValue, setInputValue] = useState<string>('')
  const [isFocused, toggleFocus] = useToggle(false)
  const [touched, toggleTouched] = useToggle(false)

  const filterOption = (candidate: FilterOptionOption<Station>, search: string) => {
    const filter = createFilter()

    const isCorrect = filter(candidate, search)
    if (isCorrect) return isCorrect

    return Boolean(
      candidate.data.synonyms.findIndex(synonym =>
        filter({ ...candidate, label: synonym }, search)
      ) + 1
    )
  }

  const filteredStations = useMemo(
    () =>
      stations
        .map(station => ({ label: station.name, value: station.uic, data: station }))
        .filter(
          option =>
            filterOption(option as FilterOptionOption<Station>, inputValue) &&
            (exclude ? option.value !== exclude.uic : true)
        )
        .map(option => ({
          ...option.data,
        })),
    [stations, filterOption, inputValue]
  )

  const handleChange = (newValue: SingleValue<Station>) => {
    setValue(newValue)
    toggleTouched(false)
    changeCallback?.()
    ref.current?.blur()
  }

  const handleChangeInput = (newInputValue: string, { action }: InputActionMeta) => {
    setInputValue(newInputValue)
    if (action === 'input-change') {
      toggleTouched(true)
      if (newInputValue === '') setValue(null)
    }
  }

  const handleFocus = () => {
    toggleFocus(true)
    if (value) {
      setInputValue(value.name)
    }
  }

  const handleBlur = () => {
    toggleFocus(false)
  }

  useDebounce(
    () => {
      if (!value && inputValue && filteredStations.length === 1) {
        handleChange(filteredStations[0])
      }
    },
    500,
    [inputValue]
  )

  useUpdateEffect(() => {
    const found = stations.find(station => station.uic === value?.uic)
    if (found && found.name !== value?.name) setValue(found)
  }, [stations])

  const menuIsOpen = isFocused || Boolean(inputValue && (!value || touched))

  return {
    ref,
    value,
    inputValue,
    handleChangeInput,
    isFocused,
    toggleFocus,
    handleChange,
    handleFocus,
    handleBlur,
    menuIsOpen,
    setValue,
    filterOption,
  }
}
