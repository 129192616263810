import { Button } from 'components/ui/Button'
import { Flex } from 'components/ui/Flex'
import styled from 'styled-components'

export const Wrapper = styled.div`
  @media screen and (max-width: 1130px) {
    .submit-button {
      width: 100%;
    }
  }
`

export const FlexContainer = styled(Flex)`
  @media screen and (max-width: 1130px) {
    width: 100%;
    flex-direction: column;
  }
`

export const StyledFlex = styled(Flex)`
  @media screen and (max-width: 1130px) {
    width: 100%;
    flex-direction: column;
    z-index: 2;

    & > div {
      // its hack emulate row-gap, Safari iOS(14 or later) does not support flexbox row-gap
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

export const DatepickerContainer = styled.div`
  @media screen and (max-width: 1130px) {
    width: 100%;
    z-index: 3;
  }
`

export const PassengersSelectContainer = styled.div`
  @media screen and (max-width: 1130px) {
    width: 100%;
  }
`

export const StyledButton = styled(Button)`
  @media screen and (max-width: 1190px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 1130px) {
    width: 100%;
    margin-top: 16px;
  }
`
