import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Tooltip } from 'components/ui/Tooltip'
import { Typography } from 'components/ui/Typography'
import { MAX_TICKETS_TO_BUY } from 'core/env'
import { PassengerTypes } from 'interfaces/Passenger'
import useTranslation from 'next-translate/useTranslation'
import { FC, useMemo, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useToggle } from 'react-use'
import { useTheme } from 'styled-components'

import {
  Arrow,
  CounterButton,
  CounterItem,
  PassengerDropdown,
  PassengerSelectButton,
  TypeSwitcher,
  TypeSwitcherButton,
  Wrapper,
} from './styles'

interface Props {
  passengers: Record<PassengerTypes, number>
  addPassenger: (passenger: PassengerTypes) => void
  removePassenger: (passenger: PassengerTypes) => void
}

enum PassengerSelectorType {
  BASIC,
  KID,
  DISABLED,
}

export const PassengersSelect: FC<Props> = ({ passengers, addPassenger, removePassenger }) => {
  const theme = useTheme()
  const { t } = useTranslation('passengersPicker')
  const [isShowDropdown, toggleDropdown] = useToggle(false)
  const [activeType, setActiveType] = useState<PassengerSelectorType>(PassengerSelectorType.BASIC)

  const passengersCounts = useMemo(() => {
    const basic = passengers[PassengerTypes.BASIC_MALE] + passengers[PassengerTypes.BASIC_FEMALE]
    const kid = passengers[PassengerTypes.KID] + passengers[PassengerTypes.KID_BABY]
    const disabled =
      passengers[PassengerTypes.DISABLED_MALE] + passengers[PassengerTypes.DISABLED_FEMALE]

    return {
      total: Object.values(passengers).reduce<number>((acc, curr) => acc + curr, 0),
      basic: {
        total: basic,
        male: passengers[PassengerTypes.BASIC_MALE],
        female: passengers[PassengerTypes.BASIC_FEMALE],
      },
      kid: {
        total: kid,
        child: passengers[PassengerTypes.KID],
        baby: passengers[PassengerTypes.KID_BABY],
      },
      disabled: {
        total: disabled,
        male: passengers[PassengerTypes.DISABLED_MALE],
        female: passengers[PassengerTypes.DISABLED_FEMALE],
      },
    }
  }, [passengers])

  const typeButtons: Array<{ value: PassengerSelectorType; label: string }> = [
    {
      value: PassengerSelectorType.BASIC,
      label: t('passengerTypeMulti.basic'),
    },
    {
      value: PassengerSelectorType.KID,
      label: t('passengerTypeMulti.kid'),
    },
    {
      value: PassengerSelectorType.DISABLED,
      label: t('passengerTypeMulti.disabled'),
    },
  ]

  const getIconColor = (isDisabled: boolean) =>
    theme.palette.core[isDisabled ? 'LIGHT_GRAY' : 'DEEP_GRAY']

  const getDisabledMinusState = (count: number) => {
    if (
      passengersCounts.basic.total + passengersCounts.disabled.total ===
      passengersCounts.kid.baby
    )
      return true

    return (
      count <= 0 ||
      (passengersCounts.kid.total >= 1 && passengersCounts.total - passengersCounts.kid.total <= 1)
    )
  }
  const getMinusTooltip = (count: number) => {
    if (count === 0) return
    if (passengersCounts.kid.total >= 1 && passengersCounts.total - passengersCounts.kid.total <= 1)
      return t('tooltip.atLeastOneAdult')
    if (
      passengersCounts.basic.total + passengersCounts.disabled.total ===
      passengersCounts.kid.baby
    )
      return t('tooltip.kid')
  }

  const forms = {
    [PassengerSelectorType.BASIC]: {
      label: t('adultsOverFifteen'),
      counters: [
        {
          label: t('gender.male'),
          sublabel: '',
          type: PassengerTypes.BASIC_MALE,
          value: passengersCounts.basic.male,
          Icon: <Icon icon={theme.icons.core.PeopleWithHighlight} size={24} color='#007AFF' />,
          tooltip: {
            plus: t('tooltip.core', { value: MAX_TICKETS_TO_BUY }),
            minus: getMinusTooltip(passengersCounts.basic.male),
          },
          disabled: {
            plus: false,
            minus: getDisabledMinusState(passengersCounts.basic.male),
          },
        },
        {
          label: t('gender.female'),
          sublabel: '',
          type: PassengerTypes.BASIC_FEMALE,
          value: passengersCounts.basic.female,
          Icon: (
            <Icon
              icon={theme.icons.core.PeopleWithHighlight}
              size={24}
              color={theme.palette.secondary.MAIN}
            />
          ),
          tooltip: {
            plus: t('tooltip.core', { value: MAX_TICKETS_TO_BUY }),
            minus: getMinusTooltip(passengersCounts.basic.female),
          },
          disabled: {
            plus: false,
            minus: getDisabledMinusState(passengersCounts.basic.female),
          },
        },
      ],
    },
    [PassengerSelectorType.KID]: {
      label: '',
      counters: [
        {
          label: t('kidType.childTitle'),
          sublabel: t('kidType.childLabel'),
          type: PassengerTypes.KID,
          value: passengersCounts.kid.child,
          Icon: (
            <Icon
              icon={theme.icons.core.ChildWithHighlight}
              size={24}
              color={theme.palette.secondary.MAIN}
            />
          ),
          tooltip: {
            plus: t('tooltip.core', { value: MAX_TICKETS_TO_BUY }),
            minus: '',
          },
          disabled: {
            plus: false,
            minus: passengersCounts.kid.child <= 0,
          },
        },
        {
          label: t('kidType.babyTitle'),
          sublabel: t('kidType.babyLabel'),
          type: PassengerTypes.KID_BABY,
          value: passengersCounts.kid.baby,
          Icon: (
            <Icon
              icon={theme.icons.core.BabyWithHighlight}
              size={24}
              color={theme.palette.secondary.MAIN}
            />
          ),
          tooltip: {
            plus: t('tooltip.kid'),
            minus: '',
          },
          disabled: {
            plus:
              passengersCounts.disabled.total + passengersCounts.basic.total ===
              passengersCounts.kid.baby,
            minus: passengersCounts.kid.baby <= 0,
          },
        },
      ],
    },
    [PassengerSelectorType.DISABLED]: {
      label: t('passengersWithExtra'),
      counters: [
        {
          label: t('gender.male'),
          sublabel: '',
          type: PassengerTypes.DISABLED_MALE,
          value: passengersCounts.disabled.male,
          Icon: <Icon icon={theme.icons.core.PeopleWithHighlight} size={24} color='#007AFF' />,
          tooltip: {
            plus: t('tooltip.core', { value: MAX_TICKETS_TO_BUY }),
            minus: getMinusTooltip(passengersCounts.disabled.male),
          },
          disabled: {
            plus: false,
            minus: getDisabledMinusState(passengersCounts.disabled.male),
          },
        },
        {
          label: t('gender.female'),
          sublabel: '',
          type: PassengerTypes.DISABLED_FEMALE,
          value: passengersCounts.disabled.female,
          Icon: (
            <Icon
              icon={theme.icons.core.PeopleWithHighlight}
              size={24}
              color={theme.palette.secondary.MAIN}
            />
          ),
          tooltip: {
            plus: t('tooltip.core', { value: MAX_TICKETS_TO_BUY }),
            minus: getMinusTooltip(passengersCounts.disabled.female),
          },
          disabled: {
            plus: false,
            minus: getDisabledMinusState(passengersCounts.disabled.female),
          },
        },
      ],
    },
  }

  const form = forms[activeType]

  const isDisabledPlus = passengersCounts.total >= MAX_TICKETS_TO_BUY
  const isDisabledMinus = passengersCounts.total === 1

  const passengersCountLabel = useMemo(() => {
    if (passengersCounts.total === 1) return null
    if (passengersCounts.basic.total === passengersCounts.total)
      return t('basicPlural', { count: passengersCounts.total })
    if (passengersCounts.kid.total === passengersCounts.total)
      return t('kidPlural', { count: passengersCounts.total })
    if (passengersCounts.disabled.total === passengersCounts.total)
      return t('disabledPlural', { count: passengersCounts.total })

    const basicStr = passengersCounts.basic.total
      ? `${passengersCounts.basic.total} ${t('passengerTypeSingleShort.basic')}`
      : ''
    const kidStr = passengersCounts.kid.total
      ? `${passengersCounts.kid.total} ${t('passengerTypeSingleShort.kid')}`
      : ''
    const disabledStr = passengersCounts.disabled.total
      ? `${passengersCounts.disabled.total} ${t('passengerTypeSingleShort.disabled')}`
      : ''

    return [basicStr, kidStr, disabledStr].filter(str => Boolean(str)).join(', ')
  }, [passengersCounts])

  return (
    <OutsideClickHandler onOutsideClick={() => toggleDropdown(false)}>
      <Wrapper>
        <PassengerSelectButton type='button' onClick={toggleDropdown}>
          <Flex container columnGap={8} alignItems='center'>
            <Icon icon={theme.icons.core.People} color={theme.palette.secondary.MAIN} />
            <Flex container direction='column' gap={2} alignItems='flex-start'>
              <Typography typography={theme.typography.variants.content.MAIN}>
                {t('passengersPlural', { count: passengersCounts.total })}
              </Typography>
              {passengersCountLabel && (
                <Box marginTop={-4}>
                  <Typography
                    typography={theme.typography.variants.description.MAIN}
                    color={theme.palette.text.DEEP_GRAY}
                  >
                    {passengersCountLabel}
                  </Typography>
                </Box>
              )}
            </Flex>
            <Arrow>
              <Icon
                size={11}
                icon={theme.icons.core.ArrowShow}
                color={theme.palette.core.DEEP_GRAY}
              />
            </Arrow>
          </Flex>
        </PassengerSelectButton>
        <PassengerDropdown isShow={isShowDropdown}>
          <TypeSwitcher>
            {typeButtons.map(({ label, value }) => (
              <TypeSwitcherButton
                type='button'
                key={value}
                isActive={value === activeType}
                data-testid={`${value}-switch`}
                onClick={() => setActiveType(value)}
              >
                <Typography typography={theme.typography.variants.content.MAIN}>{label}</Typography>
              </TypeSwitcherButton>
            ))}
          </TypeSwitcher>
          <Box marginTop={8}>
            <Typography typography={theme.typography.variants.description.MAIN}>
              {form.label}
            </Typography>
          </Box>
          {form.counters.map(counter => {
            const withWrapper = (content: JSX.Element, type: 'plus' | 'minus') => {
              let tooltipContent
              if ((isDisabledPlus || counter.disabled.plus) && type === 'plus')
                tooltipContent = counter.tooltip.plus
              else if ((isDisabledMinus || counter.disabled.minus) && type === 'minus')
                tooltipContent = counter.tooltip.minus
              if (!tooltipContent) return content
              return (
                <Tooltip
                  placement='bottom'
                  trigger='hover'
                  key={counter.label}
                  content={tooltipContent}
                  offset={[-20, 20]}
                  width={231}
                >
                  {content}
                </Tooltip>
              )
            }

            return (
              <CounterItem key={counter.label}>
                <Flex container alignItems='center' justify='space-between' gap={18}>
                  <Flex item basis={24} grow={0}>
                    {counter.Icon}
                  </Flex>
                  <Flex item grow={1}>
                    <Typography>{counter.label}</Typography>
                    {counter.sublabel && (
                      <Box>
                        <Typography
                          typography={theme.typography.variants.description.MAIN}
                          color={theme.palette.core.DEEP_GRAY}
                        >
                          {counter.sublabel}
                        </Typography>
                      </Box>
                    )}
                  </Flex>
                  <Flex container alignItems='center' columnGap={8}>
                    {withWrapper(
                      <CounterButton
                        disabled={isDisabledMinus || counter.disabled.minus}
                        onClick={() => removePassenger(counter.type)}
                        data-testid='decrement-btn'
                      >
                        <Icon
                          icon={theme.icons.counter.Remove}
                          size={16}
                          color={getIconColor(isDisabledMinus || counter.disabled.minus)}
                        />
                      </CounterButton>,
                      'minus'
                    )}
                    <Box paddingTop={4}>
                      <Typography as='p'>{counter.value}</Typography>
                    </Box>
                    {withWrapper(
                      <CounterButton
                        disabled={counter.disabled.plus || isDisabledPlus}
                        onClick={() => addPassenger(counter.type)}
                        data-testid='increment-btn'
                      >
                        <Icon
                          icon={theme.icons.counter.Add}
                          size={16}
                          color={getIconColor(isDisabledPlus || counter.disabled.plus)}
                        />
                      </CounterButton>,
                      'plus'
                    )}
                  </Flex>
                </Flex>
              </CounterItem>
            )
          })}
        </PassengerDropdown>
      </Wrapper>
    </OutsideClickHandler>
  )
}
