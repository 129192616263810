import { Button } from 'components/ui/Button'
import styled from 'styled-components'

export const TextButton = styled(Button)`
  .button-typography {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
    font-weight: 400;
    margin-right: 4px;
    &:after {
      content: ',';
      position: absolute;
      right: 2px;
    }
    &:before {
      content: attr(title);
      font-weight: bold;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      height: 0;
    }
    &:hover {
      color: ${({ theme }) => theme.palette.secondary.MAIN};
    }

    @media screen and (min-width: 1061px) and (max-width: 1360px) {
      font-size: 12px;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(189px);

  ${TextButton} {
    max-width: calc(189px / 2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:last-child {
      .button-typography {
        &:after {
          display: none;
        }
      }
    }
  }
`
