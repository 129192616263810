import { pushEvent } from 'analytics/push'
import { format } from 'date-fns'
import { AnalyticsEventType, RouteSearchEvent } from 'interfaces/GoogleAnalytics'
import { PassengerTypes } from 'interfaces/Passenger'
import { Station } from 'interfaces/Station'

interface IStationsMap {
  [key: string]: Station
}

export interface RouteSearchParams {
  origin: Station
  destination: Station
  outboundDate: Date
  inboundDate: Date | null
  passengers: Record<PassengerTypes, number>
}

export const ticketsSearchEvent = (stations: Array<Station>, searchParams: RouteSearchParams) => {
  const { origin, destination, outboundDate, inboundDate, passengers } = searchParams

  const stationsMap: IStationsMap = stations.reduce(
    (prevVal, currVal: Station) => ({
      ...prevVal,
      [currVal.uic]: currVal,
    }),
    {}
  )

  const adultPassengers =
    passengers[PassengerTypes.BASIC_MALE] + passengers[PassengerTypes.BASIC_FEMALE]
  const kidPassengers = passengers[PassengerTypes.KID] + passengers[PassengerTypes.KID_BABY]
  const preferentialPassengers =
    passengers[PassengerTypes.DISABLED_MALE] + passengers[PassengerTypes.DISABLED_FEMALE]

  pushEvent({
    event: AnalyticsEventType.TICKET_SEARCH_RESULT,
    eventProperties: {
      eventCategory: 'reference data search',
      eventAction: 'search start',
      eventLabel: 'search success',
    },
    searchProperties: {
      searchDestinationName: stationsMap[destination.uic].name,
      searchOriginName: stationsMap[origin.uic].name,
      searchOutboundDate: format(outboundDate, 'yyyy-MM-dd'),
      searchReturnDate: inboundDate ? format(inboundDate, 'yyyy-MM-dd') : null,
    },
    ticketProducts: {
      adultPassengers,
      kidPassengers,
      preferentialPassengers,
      totalPassengers: adultPassengers + kidPassengers + preferentialPassengers,
    },
  } as RouteSearchEvent)
}
