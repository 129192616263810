import styled, { css } from 'styled-components'
import { indent } from 'styles/mixins'

export const PassengerSelectButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  border: 1px solid ${({ theme }) => theme.palette.core.TRANSPARENT};
  width: 210px;
  height: 50px;
  border-radius: 12px;
  position: relative;
  span {
    line-height: 1.3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  ${() =>
    indent('padding', {
      top: 0,
      bottom: 0,
      left: 16,
      right: 32,
    })}

  @media screen and (max-width: 1130px) {
    width: 100%;
  }
`

export const PassengerDropdown = styled.div<{ isShow: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  left: -50px;
  width: 300px;
  background-color: ${({ theme }) => theme.palette.core.WHITE};
  border: 1px solid ${({ theme }) => theme.palette.core.GRAY};
  border-radius: 12px;
  padding: 16px 16px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  ${({ isShow }) =>
    isShow &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  @media screen and (max-width: 1130px) {
    width: 100%;
    left: 0;
  }
`

export const Arrow = styled.div`
  position: absolute;
  height: 11px;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`

export const TypeSwitcher = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
`

export const TypeSwitcherButton = styled.button<{ isActive: boolean }>`
  border: none;
  flex-grow: 1;
  background: none;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 8px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.palette.core.WHITE};
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    `}

  span {
    line-height: 1;
    letter-spacing: -0.8px;

    @media screen and (max-width: 460px) {
      font-size: 12px;
    }
  }

  &:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const CounterButton = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
  font-family: sans-serif;
  line-height: 1;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: pointer;
`

export const CounterItem = styled.div`
  padding: 14px 0;
  margin-top: 2px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
`

export const Wrapper = styled.div`
  position: relative;
  user-select: none;
  ${CounterItem} {
    &:last-child {
      border: none;
    }
  }
`
