import 'react-datepicker/dist/react-datepicker.css'

import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import { SALES_DEPTH_LIMIT } from 'core/env'
import { addDays } from 'date-fns'
import useTranslation from 'next-translate/useTranslation'
import { FC, useEffect, useMemo, useState } from 'react'
import DatePicker from 'react-datepicker'
import OutsideClickHandler from 'react-outside-click-handler'
import { useWindowSize } from 'react-use'
import { useTheme } from 'styled-components'
import { format } from 'utilities/format'
import { getLocale } from 'utilities/getLocale'

import { Hints } from '../Hints'
import {
  DatepickerButton,
  DatepickerButtonContainer,
  DatepickerWrapper,
  DesktopButton,
  HintBox,
  MobileButton,
  StyledBox,
  Wrapper,
} from './styles'

const DATEPICKER_FORMAT = 'd MMMM, EEEEEE'

interface Props {
  onSelectDates: (dates: [Date | null, Date | null]) => void
  focus?: boolean
  initialFrom?: Date | null
  initialTo?: Date | null
  clearFocus?: () => void
  useHints?: boolean
  isHomeScreen?: boolean
}

export const Datepicker: FC<Props> = ({
  onSelectDates,
  focus = false,
  initialFrom,
  initialTo,
  useHints = true,
  isHomeScreen = true,
  clearFocus,
}) => {
  const theme = useTheme()
  const { t, lang } = useTranslation('datepicker')
  const { width } = useWindowSize()

  const [startDate, setStartDate] = useState<Date | null>(initialFrom || null)
  const [endDate, setEndDate] = useState<Date | null>(initialTo || null)

  const [focused, setFocused] = useState<'start' | 'end' | null>(null)

  const focusStart = () => {
    setStartDate(null)
    setEndDate(null)
    setFocused('start')
  }

  const focusEnd = () => {
    setEndDate(null)
    setFocused('end')
  }

  useEffect(() => {
    if (focus && !focused) {
      focusStart()
      clearFocus?.()
    }
  }, [focus, clearFocus, focused])

  useEffect(() => {
    if (focused === 'end' && !startDate) setStartDate(new Date())
  }, [focused, startDate, setStartDate])

  useEffect(() => {
    onSelectDates([startDate, endDate])
  }, [startDate, endDate])

  const blurAll = () => {
    setFocused(null)
  }

  const handleDatesChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    if (start && !end) focusEnd()
    if (start && end) blurAll()
  }

  const disableReturnTicket = () => {
    setEndDate(null)
    blurAll()
  }

  const today = new Date()
  const tomorrow = addDays(today, 1)
  const startDateHints = useMemo(
    () => [format(today, 'd MMMM', lang), format(tomorrow, 'd MMMM', lang)],
    [lang]
  )

  const isFocusedStart = focused === 'start'
  const isFocusedEnd = focused === 'end'
  const isShowDatepicker = isFocusedStart || isFocusedEnd
  const isMobile = width <= 780

  return (
    <OutsideClickHandler onOutsideClick={blurAll}>
      <Wrapper>
        <Flex container direction='column'>
          <DatepickerButtonContainer container columnGap={4}>
            <Flex container direction='column'>
              <DatepickerButton
                type='button'
                data-testid='datepicker-button'
                focused={isFocusedStart}
                left
                onFocus={focusStart}
                onClick={focusStart}
              >
                <Flex container columnGap={8} alignItems='center'>
                  <Icon icon={theme.icons.core.Calendar} color={theme.palette.secondary.MAIN} />
                  <Typography typography={theme.typography.variants.content.MAIN}>
                    {startDate ? format(startDate, DATEPICKER_FORMAT, lang) : t('outbound')}
                  </Typography>
                </Flex>
              </DatepickerButton>
            </Flex>
            <Flex container direction='column'>
              <DatepickerButton
                type='button'
                data-testid='datepicker-button'
                focused={isFocusedEnd}
                right
                onClick={focusEnd}
                onFocus={focusEnd}
                onBlur={blurAll}
              >
                <Flex container columnGap={8} alignItems='center'>
                  <Icon icon={theme.icons.core.Calendar} color={theme.palette.secondary.MAIN} />
                  <Typography typography={theme.typography.variants.content.MAIN}>
                    {endDate ? format(endDate, DATEPICKER_FORMAT, lang) : t('inbound')}
                  </Typography>
                </Flex>
              </DatepickerButton>
            </Flex>
          </DatepickerButtonContainer>
          {useHints && (
            <HintBox marginTop={6} marginLeft={8}>
              <Hints
                hints={startDateHints}
                onHintClick={(hint: string) => {
                  const date = hint === format(today, 'd MMMM', lang) ? today : tomorrow
                  handleDatesChange([date, endDate])
                }}
              />
            </HintBox>
          )}
        </Flex>
        <DatepickerWrapper isShow={isShowDatepicker} isHomeScreen={isHomeScreen}>
          <Flex container justify='space-between' alignItems='center'>
            <StyledBox paddingTop={10} paddingBottom={10}>
              <Typography
                typography={theme.typography.variants.content.BOLD}
                color={theme.palette.text.DEEP_GRAY}
              >
                {t('pick')} {t(isFocusedStart ? 'departure' : 'return')} {}
              </Typography>
            </StyledBox>
            {isFocusedEnd && (
              <DesktopButton variant='flat' fill='outlined' onClick={disableReturnTicket}>
                {t('returnTicket')}
              </DesktopButton>
            )}
          </Flex>
          <Box marginTop={16}>
            <DatePicker
              selected={startDate}
              onChange={handleDatesChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              monthsShown={isMobile ? 1 : 2}
              locale={getLocale(lang)}
              inline
              minDate={new Date()}
              maxDate={addDays(new Date(), SALES_DEPTH_LIMIT)}
            />
          </Box>
          {isFocusedEnd && isMobile && (
            <MobileButton variant='flat' fill='outlined' onClick={disableReturnTicket}>
              {t('returnTicket')}
            </MobileButton>
          )}
        </DatepickerWrapper>
      </Wrapper>
    </OutsideClickHandler>
  )
}
