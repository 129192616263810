import { addYears, compareDesc, parse } from 'date-fns'
import { PassengerTypes } from 'interfaces/Passenger'
import { Station } from 'interfaces/Station'

export const defaultPassengers = {
  [PassengerTypes.BASIC_MALE]: 1,
  [PassengerTypes.BASIC_FEMALE]: 0,
  [PassengerTypes.KID]: 0,
  [PassengerTypes.KID_BABY]: 0,
  [PassengerTypes.DISABLED_MALE]: 0,
  [PassengerTypes.DISABLED_FEMALE]: 0,
}

const getOutboundDate = (outbound: string | string[] | undefined) => {
  let outboundDate = typeof outbound === 'string' ? parse(outbound, 'ddMM', new Date()) : new Date()
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  if (compareDesc(outboundDate, today) > 0) outboundDate = addYears(outboundDate, 1)
  return outboundDate.toISOString()
}

const getInboundDate = (inbound: string | string[] | undefined, outboundDate: string) => {
  let inboundDate = typeof inbound === 'string' ? parse(inbound, 'ddMM', new Date()) : null
  if (
    inboundDate &&
    outboundDate &&
    Boolean(compareDesc(inboundDate, new Date(outboundDate)) + 1)
  ) {
    inboundDate = addYears(inboundDate, 1)
  }
  return inboundDate?.toISOString() || null
}

export const getInitialSearchValues = (
  {
    origin: originShortCode,
    destination: destinationShortCode,
    outbound,
    inbound,
    passengers: passengersStr,
  }: Record<string, string | string[] | undefined>,
  stations: Array<Station>
) => {
  const origin = stations.find(city => city.shortCode === originShortCode) || stations[0]
  const destination = stations.find(city => city.shortCode === destinationShortCode) || stations[1]
  const outboundDate = getOutboundDate(outbound)
  const inboundDate = getInboundDate(inbound, outboundDate)

  const passengers =
    typeof passengersStr !== 'string'
      ? defaultPassengers
      : passengersStr.split(',').reduce(
          (acc, passenger) => {
            const type = passenger.replace(/[0-9]/g, '')
            const value = passenger.replace(/[A-Za-z_]/g, '')
            return {
              ...acc,
              [type]: Number(value),
            }
          },
          {
            ...defaultPassengers,
            [PassengerTypes.BASIC_MALE]: 0,
          } as Record<PassengerTypes, number>
        )

  return {
    isSingle: !inboundDate,
    origin,
    destination,
    outboundDate,
    inboundDate,
    passengers,
  }
}
