import { Flex } from 'components/ui/Flex'
import { Grid } from 'components/ui/Grid'
import { Skeleton } from 'components/ui/Skeleton'
import { FC } from 'react'
import { useTheme } from 'styled-components'
import uniqid from 'uniqid'

import { TextButton, Wrapper } from './styles'

interface Props {
  hints: Array<string>
  onHintClick: (hint: string) => void
}

export const Hints: FC<Props> = ({ hints, onHintClick }) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Flex container>
        {hints.length === 0 && (
          <Grid container columnGap={6} cols={2}>
            <Skeleton width={70} height={18} radius={6} />
            <Skeleton width={70} height={18} radius={6} />
          </Grid>
        )}

        {hints.map(hint => (
          <TextButton
            type='button'
            tabIndex={-1}
            key={uniqid()}
            onClick={() => onHintClick(hint)}
            variant='text'
            textColor={theme.palette.text.DEEP_GRAY}
          >
            {hint}
          </TextButton>
        ))}
      </Flex>
    </Wrapper>
  )
}
