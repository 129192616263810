import { Flex } from 'components/ui/Flex'
import { Icon } from 'components/ui/Icon'
import { Typography } from 'components/ui/Typography'
import { Station } from 'interfaces/Station'
import useTranslation from 'next-translate/useTranslation'
import { FC, MutableRefObject, useEffect } from 'react'
import Select from 'react-select'
import { getSortedStations } from 'store/features/stations/selectors'
import { useAppSelector } from 'store/hooks'
import { useTheme } from 'styled-components'

import { Hints } from '../Hints'
import { useSelect } from './hooks/useSelect'
import {
  Divider,
  FlexContainer,
  HintBox,
  SelectFlexContainer,
  SelectIconWrapper,
  SelectWrapper,
  SwitchButton,
  Wrapper,
} from './styles'

interface Props {
  onSelectCities: (cities: [Station | null, Station | null]) => void
  initialFrom?: Station | null
  initialTo?: Station | null
  cityFrom?: Station | null
  cityTo?: Station | null
  useHints?: boolean
  autofocus?: boolean
}

export const CitySelect: FC<Props> = ({
  onSelectCities,
  initialFrom,
  initialTo,
  cityFrom,
  cityTo,
  useHints = true,
  autofocus = false,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const stations = useAppSelector(getSortedStations)

  const cityToSelect = useSelect({
    stations,
    initial: initialTo,
    exclude: cityFrom,
  })

  const cityFromSelect = useSelect({
    stations,
    initial: initialFrom,
    exclude: cityTo,
    changeCallback: () => {
      cityToSelect.ref.current?.focus()
    },
  })

  useEffect(() => {
    onSelectCities([cityFromSelect.value, cityToSelect.value])
  }, [cityFromSelect.value, cityToSelect.value])

  const switchValues = () => {
    const { value: fromValue } = cityFromSelect
    const { value: toValue } = cityToSelect

    cityFromSelect.setValue(toValue)
    cityToSelect.setValue(fromValue)
    onSelectCities([toValue, fromValue])
  }

  const noOptionsMessage = () => (
    <Typography
      typography={theme.typography.variants.description.MAIN}
      color={theme.palette.text.DEEP_GRAY}
    >
      {t('ticketSearch:notFound')}
    </Typography>
  )

  const excludedStations = stations.filter(station => station.uic !== cityFromSelect.value?.uic)

  // Hardcoded popular stations UIC codes
  const stationsHints = ['2704600', '2704807', '2704822', '2704980']

  const hints = stationsHints
    .map(uic => excludedStations.find(s => s.uic === uic)?.name)
    .filter(label => Boolean(label))
    .slice(0, 2) as Array<string>

  const cityFromIsSelected = Boolean(cityFromSelect.value)
  const cityToIsSelected = Boolean(cityToSelect.value)

  return (
    <Wrapper
      cityFromSelectIsFocused={cityFromSelect.isFocused}
      cityToSelectIsFocused={cityToSelect.isFocused}
    >
      <FlexContainer container height={useHints ? 'auto' : 50} columnGap={4}>
        <SelectFlexContainer container direction='column'>
          <SelectWrapper left hasValue={false} cityIsSelected={cityFromIsSelected}>
            <SelectIconWrapper isShow cityIsSelected={cityFromIsSelected}>
              <Icon icon={theme.icons.core.Pin} color={theme.palette.secondary.MAIN} />
            </SelectIconWrapper>
            <Select
              autoFocus={autofocus}
              blurInputOnSelect
              ref={cityFromSelect.ref as MutableRefObject<null>}
              inputValue={cityFromSelect.inputValue}
              onInputChange={cityFromSelect.handleChangeInput}
              onFocus={cityFromSelect.handleFocus}
              onBlur={cityFromSelect.handleBlur}
              onChange={cityFromSelect.handleChange}
              value={cityFromSelect.value}
              menuIsOpen={cityFromSelect.menuIsOpen}
              filterOption={cityFromSelect.filterOption}
              options={stations}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uic}
              noOptionsMessage={noOptionsMessage}
              placeholder={t('ticketSearch:from')}
              instanceId='city-select-from'
              classNamePrefix='search-select'
              inputId='city-select-input-from'
            />
          </SelectWrapper>
          {useHints && (
            <HintBox marginTop={6} marginLeft={8} cityIsSelected={cityFromIsSelected}>
              <Hints
                hints={hints}
                onHintClick={(hint: string) => {
                  const found = excludedStations.find(station => station.name === hint)
                  cityFromSelect.handleChange(found!)
                }}
              />
            </HintBox>
          )}
        </SelectFlexContainer>
        <Divider isFocused={cityFromSelect.isFocused || cityToSelect.isFocused} />
        <SwitchButton
          type='button'
          tabIndex={-1}
          onClick={switchValues}
          disabled={!cityFromSelect.value && !cityToSelect.value}
          data-testid='switch'
          useHints={useHints}
        >
          <Icon icon={theme.icons.core.SwitchArrow} color={theme.palette.core.DEEP_GRAY} />
        </SwitchButton>
        <SelectFlexContainer container direction='column'>
          <SelectWrapper right hasValue={false} cityIsSelected={cityToIsSelected}>
            <SelectIconWrapper isShow cityIsSelected={cityToIsSelected}>
              <Icon icon={theme.icons.core.Pin} color={theme.palette.secondary.MAIN} />
            </SelectIconWrapper>
            <Select
              blurInputOnSelect
              ref={cityToSelect.ref as MutableRefObject<null>}
              inputValue={cityToSelect.inputValue}
              onInputChange={cityToSelect.handleChangeInput}
              onFocus={cityToSelect.handleFocus}
              onBlur={cityToSelect.handleBlur}
              onChange={cityToSelect.handleChange}
              value={cityToSelect.value}
              menuIsOpen={cityToSelect.menuIsOpen}
              filterOption={cityToSelect.filterOption}
              options={excludedStations}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.uic}
              noOptionsMessage={noOptionsMessage}
              placeholder={t('ticketSearch:to')}
              instanceId='city-select-to'
              classNamePrefix='search-select'
              inputId='city-select-input-to'
            />
          </SelectWrapper>
          <HintBox marginTop={6} marginLeft={8} cityIsSelected={cityToIsSelected}>
            <Flex container columnGap={2}>
              {useHints && (
                <Hints
                  hints={hints}
                  onHintClick={(hint: string) => {
                    const found = excludedStations.find(station => station.name === hint)
                    cityToSelect.handleChange(found!)
                  }}
                />
              )}
            </Flex>
          </HintBox>
        </SelectFlexContainer>
      </FlexContainer>
    </Wrapper>
  )
}
