import { Box } from 'components/ui/Box'
import { Button } from 'components/ui/Button'
import { Flex } from 'components/ui/Flex'
import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { indent } from 'styles/mixins'

export const Wrapper = styled.div`
  position: relative;

  @media screen and (max-width: 1130px) {
    width: 100%;
  }
`

interface DatepickerButtonProps {
  left?: boolean
  right?: boolean
  focused?: boolean
}

export const DatepickerButton = styled.button<DatepickerButtonProps>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  border: 1px solid ${({ theme }) => theme.palette.core.TRANSPARENT};
  width: 170px;
  height: 50px;
  ${() =>
    indent('padding', {
      top: 14,
      bottom: 16,
      left: 16,
      right: 32,
    })}
  ${({ left }) =>
    left &&
    css`
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    `}
  ${({ right }) =>
    right &&
    css`
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    `}

  ${({ focused }) =>
    focused &&
    css`
      border-color: ${({ theme }) => theme.palette.secondary.MAIN};
      background-color: ${({ theme }) => theme.palette.core.WHITE};
    `}
  
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  @media screen and (max-width: 1360px) {
    padding: 16px;
  }

  @media screen and (max-width: 1330px) {
    width: 140px;
  }

  @media screen and (max-width: 1225px) {
    width: 130px;
  }

  @media screen and (max-width: 1150px) {
    width: 120px;
  }

  @media screen and (max-width: 1130px) {
    width: 100%;
    padding: 16px 32px 16px 16px;
  }

  @media screen and (max-width: 390px) {
    > div {
      width: 95px;
    }
  }

  @media screen and (max-width: 360px) {
    > div {
      width: 75px;
    }
  }
`

interface DatepickerWrapperProps {
  isShow: boolean
  isHomeScreen: boolean
}

export const DatepickerWrapper = styled.div<DatepickerWrapperProps>`
  ${indent('padding', { vertical: 20, horizontal: 24 })}
  background-color: ${({ theme }) => theme.palette.core.WHITE};
  border-radius: 12px;
  width: 635px;
  position: absolute;
  top: calc(100% + 8px);
  left: calc(-317.5px / 2);

  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  ${({ isShow }) =>
    isShow &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  .react-datepicker {
    border: none;
    background: none;
    display: flex;
    column-gap: 25px;
    &__navigation {
      top: 0;
      z-index: 10;

      &-icon {
        &:before {
          border-color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
          transition: all 0.2s ease;
        }
        &:hover {
          &:before {
            border-color: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
          }
        }
      }

      &--previous {
        left: 0;
      }
      &--next {
        right: 0;
      }
    }
    &__header {
      border: none;
      background: none;
      padding: 0;
    }
    &__current-month {
      color: ${({ theme }) => theme.palette.core.BLACK};
      text-transform: capitalize;
      margin-bottom: 16px;
      font-size: 16px;
      margin-top: 8px;
    }

    &__day-name {
      text-transform: capitalize;
      font-size: 14px;
      color: ${({ theme }) => theme.palette.text.DEEP_GRAY};
      width: 40px;
      height: 40px;
      margin: 0 0 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    &__month {
      margin: 0;
    }
    &__week {
      display: flex;
    }
    &__day {
      flex-grow: 1;
      text-transform: capitalize;
      font-size: 14px;
      color: ${({ theme }) => theme.palette.text.DEEP_GRAY};
      background-color: ${({ theme }) => theme.palette.core.WHITE};
      border-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
      border-width: 1px;
      border-style: solid;
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 -1px -1px 0; // hack for prevent double borders
      &:hover {
        background-color: ${({ theme }) => transparentize(0.2, theme.palette.secondary.MAIN)};
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
        border-radius: 0;
      }
      &--in-range,
      &--in-selecting-range {
        background-color: ${({ theme }) => transparentize(0.9, theme.palette.secondary.MAIN)};
        border-radius: 0;
      }
      &--in-selecting-range {
        background-color: ${({ theme }) => theme.palette.core.TRANSPARENT};
      }
      &--selected,
      &--range-start,
      &--range-end {
        background-color: ${({ theme }) => theme.palette.secondary.MAIN};
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
        border-radius: 0;
      }
      &--outside-month {
        display: block;
        opacity: 0;
        pointer-events: none;
      }
      &--disabled {
        pointer-events: none;
        background-color: ${({ theme }) => transparentize(0.7, theme.palette.core.LIGHT_GRAY)};
      }
    }

    @media screen and (max-width: 780px) {
      flex-direction: column;

      &__day-name {
        width: 14%; // 100% / 7 = 14%
        height: 35px;
        margin-bottom: 16px;
      }

      .react-datepicker {
        &__navigation {
          &--previous {
            left: -9px;
          }
          &--next {
            right: -9px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1130px) {
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }

  @media screen and (max-width: 780px) {
    width: ${({ isHomeScreen }) => (isHomeScreen ? 'calc(100% + 32px)' : '100%')};
    top: -77px;
    padding: 16px;
    box-shadow: 0px 0px 20px 8px rgba(0, 0, 0, 0.11);
  }
`

export const DatepickerButtonContainer = styled(Flex)`
  @media screen and (max-width: 1130px) {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 2px));
  }
`

export const DesktopButton = styled(Button)`
  @media screen and (max-width: 780px) {
    display: none;
  }
`

export const MobileButton = styled(Button)`
  @media screen and (max-width: 780px) {
    width: 100%;
    margin-top: 16px;
  }

  @media screen and (max-width: 330px) {
    padding: 8.5px 20px;
  }
`

export const StyledBox = styled(Box)`
  @media screen and (max-width: 780px) {
    padding-bottom: 4px;
  }
`

export const HintBox = styled(Box)`
  @media screen and (max-width: 1360px) {
    margin-left: 0;
  }
`
