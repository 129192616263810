import { Box } from 'components/ui/Box'
import { Flex } from 'components/ui/Flex'
import { lighten } from 'polished'
import styled, { css, keyframes } from 'styled-components'
import { indent } from 'styles/mixins'
import { noScrollbar } from 'styles/mixins/noScrollbar'
import { pixelize } from 'utilities/pixelize'

interface SelectWrapperProps {
  left?: boolean
  right?: boolean
  focused?: boolean
  hasValue?: boolean
  cityIsSelected: boolean
}

interface WrapperProps {
  cityFromSelectIsFocused: boolean
  cityToSelectIsFocused: boolean
}

interface DividerProps {
  isFocused: boolean
}

interface HintBoxProps {
  cityIsSelected: boolean
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
`

export const SelectWrapper = styled.div<SelectWrapperProps>`
  position: relative;
  z-index: 2;
  .search-select {
    &__control {
      cursor: pointer;
      height: 50px;
      background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
      border: 1px solid ${({ theme }) => theme.palette.core.TRANSPARENT};
      width: 165px;
      ${({ hasValue }) =>
        indent('padding', {
          top: 11,
          bottom: 10,
          left: hasValue ? 16 : 40,
          right: 16,
        })}

      ${({ left }) =>
        left &&
        css`
          border-radius: 12px 0 0 12px;

          @media screen and (max-width: 1130px) {
            border-radius: 12px 12px 0 0;
          }
        `}
  
      ${({ right }) =>
        right &&
        css`
          border-radius: 0 12px 12px 0;

          @media screen and (max-width: 1130px) {
            border-radius: 0 0 12px 12px;
          }
        `}

      ${({ focused }) =>
        focused &&
        css`
          border-color: ${({ theme }) => theme.palette.secondary.MAIN};
        `}
      font-family: 'Open Sans', sans-serif;
      min-height: auto;

      &:hover {
        background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
        border: 1px solid ${({ theme }) => theme.palette.core.TRANSPARENT};
        box-shadow: none;
      }

      &--is-focused {
        .search-select__single-value,
        .search-select__placeholder {
          visibility: hidden;
        }
      }

      &--is-focused,
      &--menu-is-open {
        border: 1px solid ${({ theme }) => theme.palette.secondary.MAIN};
        box-shadow: none;
        background-color: ${({ theme }) => theme.palette.core.WHITE};
        &:hover {
          border: 1px solid ${({ theme }) => theme.palette.secondary.MAIN};
          box-shadow: none;
          background-color: ${({ theme }) => theme.palette.core.WHITE};
        }
      }

      @media screen and (max-width: 1439px) {
        width: 180px;
      }

      @media screen and (max-width: 1390px) {
        width: 155px;
      }

      @media screen and (max-width: 1260px) {
        width: 140px;
      }

      @media screen and (max-width: 1225px) {
        width: 130px;
      }

      @media screen and (max-width: 1130px) {
        width: 100%;
        height: 72px;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: 0;
      transition: 0.3s;

      @media screen and (max-width: 1130px) {
        ${({ cityIsSelected }) =>
          !cityIsSelected &&
          css`
            margin-bottom: 22px;
          `}
      }
    }

    &__single-value {
      visibility: visible;
    }

    &__single-value,
    &__placeholder {
      color: ${({ theme }) => theme.palette.text.MAIN};
      font-size: ${({ theme }) => pixelize(theme.typography.sizes.DEFAULT)};
      margin: 0;
      line-height: 1.3;
    }

    &__input {
      color: ${({ theme }) => theme.palette.text.MAIN} !important;
      font-size: ${({ theme }) => pixelize(theme.typography.sizes.DEFAULT)} !important;
      &-container {
        margin: 0px 0 2px;
        padding: 0;
      }
    }

    &__dropdown-indicator {
      display: none;
    }

    &__menu {
      z-index: 10;
      border: none;
      margin: 4px 0 0;
      width: 100%;
      border-radius: 0 0 12px 12px;
      box-shadow: ${({ theme }) => theme.shadow.primary};
      animation: ${fadeIn} 0.2s ease;
    }

    &__menu-list {
      padding-top: 16px;
      padding-bottom: 8px;
      ${noScrollbar()}
    }

    &__option {
      font-family: 'Open Sans', sans-serif;
      cursor: pointer;
      color: ${({ theme }) => theme.palette.text.MAIN};
      font-size: ${({ theme }) => pixelize(theme.typography.sizes.DEFAULT)};

      ${() =>
        indent('padding', {
          vertical: 8,
          horizontal: 16,
        })}

      &--is-focused {
        background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
      }

      &--is-selected {
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
        background-color: ${({ theme }) => theme.palette.primary.MAIN};
      }

      &:active {
        background-color: ${({ theme }) => lighten(0.1, theme.palette.primary.MAIN)};
        color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
      }
    }
  }
`

interface SelectIconWrapperProps {
  isShow?: boolean
  cityIsSelected: boolean
}

export const SelectIconWrapper = styled.div<SelectIconWrapperProps>`
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  ${({ isShow }) =>
    isShow &&
    css`
      visibility: visible;
      opacity: 1;
    `}

  @media screen and (max-width: 1130px) {
    ${({ cityIsSelected }) =>
      !cityIsSelected &&
      css`
        top: calc(50% - 10px);
      `}
  }
`

export const SwitchButton = styled.button<{ useHints: boolean }>`
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: ${({ useHints }) =>
    `calc(50% - ${useHints ? 13 : 0}px)`}; // 13 = 26(18px height of buttons + 8px margin) / 2
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  border-radius: 50%;
  padding: 5px 5px 2px;
  border: 3px solid ${({ theme }) => theme.palette.core.WHITE};
  transition: all 0.3s ease;

  &[disabled] {
    pointer-events: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.MAIN};
    .icon {
      fill: ${({ theme }) => theme.palette.core.WHITE};
    }
  }

  .icon {
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 1130px) {
    top: calc(50% - 16px);
    right: 17px;
    left: unset;
    background-color: ${({ theme }) => theme.palette.core.WHITE};
    transform: rotate(90deg);
  }
`

export const Wrapper = styled.div<WrapperProps>`
  position: relative;

  @media screen and (max-width: 1130px) {
    width: 100%;
    z-index: 3;

    ${({ cityFromSelectIsFocused }) =>
      cityFromSelectIsFocused &&
      `
      z-index: 4;
    `}

    ${({ cityToSelectIsFocused }) =>
      cityToSelectIsFocused &&
      `
      z-index: 4;
    `}
  }
`

export const Divider = styled.div<DividerProps>`
  display: none;
  width: calc(100% - 80px);
  height: 1px;
  background: ${({ theme }) => theme.palette.core.LIGHT_GRAY};
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translate(16px, -50%);

  @media screen and (max-width: 1130px) {
    display: block;
  }

  ${({ isFocused }) =>
    isFocused &&
    `
    background: transparent;
  `}
`

export const SelectFlexContainer = styled(Flex)`
  @media screen and (max-width: 1130px) {
    position: relative;

    &:first-child {
      border-radius: 12px 12px 0 0;
      z-index: 2;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
    }
  }
`

export const HintBox = styled(Box)<HintBoxProps>`
  @media screen and (max-width: 1360px) {
    margin-left: 0;
  }

  @media screen and (max-width: 1130px) {
    margin: 0;
    position: absolute;
    bottom: 11px;
    left: 39px;
    z-index: 2;

    span {
      font-size: 12px;
    }

    ${({ cityIsSelected }) =>
      cityIsSelected &&
      css`
        display: none;
      `}
  }
`

export const FlexContainer = styled(Flex)`
  @media screen and (max-width: 1130px) {
    height: auto;
    flex-direction: column;
  }
`
