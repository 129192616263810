import styled, { css, keyframes } from 'styled-components'
import { AnyColor } from 'styles/theme/palette'
import { isNumber } from 'utilities/isNumber'
import { pixelize } from 'utilities/pixelize'

import { Props } from './props'

const shineAnimation = keyframes`
  from { background-position: -200px 0 }
  to { background-position: calc(200px + 100%) 0 }
`

const shineBase = css`
  background-size: 200px 100%;
  background-repeat: no-repeat;
`

const shineGradient = (mainColor: AnyColor) => css`
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    ${mainColor},
    rgba(255, 255, 255, 0)
  );
`

export const Wrapper = styled.span<Props>`
  display: inline-block;
  user-select: none;
  position: relative;
  width: ${({ width = '100%' }) => (isNumber(width) ? pixelize(width) : width)};
  height: ${({ height = '100%' }) => (isNumber(height) ? pixelize(height) : height)};
  border-radius: ${({ radius = 16 }) => (isNumber(radius) ? pixelize(radius) : radius)};
  background-color: ${({ theme }) => theme.palette.core.VERY_LIGHT_GRAY};
  ${shineBase}
  ${() => shineGradient('#EEE9E9')}
  animation: ${shineAnimation} 1.5s infinite;
`
