import { RouteSearchParams, ticketsSearchEvent } from 'analytics/events/ticketsSearch'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { PassengerTypes } from 'interfaces/Passenger'
import { Station } from 'interfaces/Station'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useAppSelector } from 'store/hooks'
import { getEntities } from 'store/selectors/getEntities'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  origin: yup.mixed().required(),
  destination: yup.mixed().required(),
  outboundDate: yup.mixed().required(),
})

export interface Values<T = Date> {
  origin: Station | null
  destination: Station | null
  outboundDate: T | null
  inboundDate: T | null
  passengers: Record<PassengerTypes, number>
}

export const defaultInitialValues: Values<string> = {
  origin: null,
  destination: null,
  outboundDate: null,
  inboundDate: null,
  passengers: {
    [PassengerTypes.BASIC_MALE]: 0,
    [PassengerTypes.BASIC_FEMALE]: 0,
    [PassengerTypes.KID]: 0,
    [PassengerTypes.KID_BABY]: 0,
    [PassengerTypes.DISABLED_MALE]: 0,
    [PassengerTypes.DISABLED_FEMALE]: 0,
  },
}

export const getDefaultInitialValues = (): Values<string> => ({
  ...defaultInitialValues,
  passengers: {
    ...defaultInitialValues.passengers,
    [PassengerTypes.BASIC_MALE]: 1,
  },
})

const buildQuery = (values: Values) => {
  const query: Record<string, string> = {}

  const passengersString = Object.entries(values.passengers)
    .filter(([_, value]) => Boolean(value))
    .map(([key, value]) => `${key}${value}`)
    .join(',')

  if (values.origin) query.origin = values.origin.shortCode
  if (values.destination) query.destination = values.destination.shortCode
  if (values.outboundDate) query.outbound = format(values.outboundDate, 'ddMM')
  if (values.inboundDate) query.inbound = format(values.inboundDate, 'ddMM')
  query.passengers = passengersString

  return query
}

export const useForm = (initialValues: Partial<Values<string>> = getDefaultInitialValues()) => {
  const stations = useAppSelector(getEntities<Station>('stations'))

  const router = useRouter()

  const [passengers, setPassengers] = useState<Record<PassengerTypes, number>>(
    initialValues.passengers || defaultInitialValues.passengers
  )

  const onSubmit = async (values: Values) => {
    await router.push({
      pathname: '/search/results',
      query: buildQuery(values),
    })
    /* Analytics event */
    ticketsSearchEvent(stations ?? [], values as RouteSearchParams)
  }

  const formik = useFormik<Values>({
    initialValues: {
      ...defaultInitialValues,
      ...initialValues,
      outboundDate: new Date(initialValues.outboundDate || ''),
      inboundDate: initialValues.inboundDate ? new Date(initialValues.inboundDate || '') : null,
    } as Values,
    onSubmit,
    validationSchema,
    validateOnChange: true,
    isInitialValid: Boolean(
      initialValues.origin && initialValues.destination && initialValues.outboundDate
    ),
  })

  const addPassenger = (type: PassengerTypes) => {
    setPassengers({
      ...passengers,
      [type]: (passengers[type] || 0) + 1,
    })
  }

  const removePassenger = (type: PassengerTypes) => {
    if (!passengers[type]) return
    setPassengers({
      ...passengers,
      [type]: (passengers[type] || 0) - 1,
    })
  }

  useUpdateEffect(() => {
    const calculateLength = (p: Record<PassengerTypes, number>) =>
      Object.values(p).reduce<number>((acc, curr) => acc + curr, 0)

    if (calculateLength(formik.values.passengers) !== calculateLength(passengers))
      void formik.setFieldValue('passengers', passengers)
  }, [passengers])

  return {
    ...formik,
    addPassenger,
    removePassenger,
  }
}
