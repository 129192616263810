import { PassengersSelect } from 'components/shared/SearchTicketsForm/libs/PassengersSelect'
import { Station } from 'interfaces/Station'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FC, useMemo } from 'react'
import { useToggle } from 'react-use'
import { getInitialSearchValues } from 'store/features/ticketsSearch/utilitites/getInitialSearchValues'
import { useAppSelector } from 'store/hooks'
import { getEntities } from 'store/selectors/getEntities'

import { getDefaultInitialValues, useForm, Values } from './hooks/useForm'
import { CitySelect } from './libs/CitySelect'
import { Datepicker } from './libs/Datepicker'
import {
  DatepickerContainer,
  FlexContainer,
  PassengersSelectContainer,
  StyledButton,
  StyledFlex,
  Wrapper,
} from './styles'

interface Props {
  useHints?: boolean
  autofocus?: boolean
  isHomeScreen?: boolean
  initialValues?: Values<string>
}

export const SearchTicketsForm: FC<Props> = ({
  useHints = true,
  autofocus = false,
  isHomeScreen = true,
  initialValues: rawInitialValues,
}) => {
  const { t } = useTranslation('ticketSearch')
  const router = useRouter()

  const [focusOnDatepicker, toggleFocus] = useToggle(false)

  const stations = useAppSelector(getEntities<Station>('stations'))

  const initialValues = useMemo(() => {
    if (rawInitialValues) return rawInitialValues
    return router.query.outbound
      ? getInitialSearchValues(router.query, stations)
      : getDefaultInitialValues()
  }, [router])

  const formik = useForm(initialValues)

  const handleSelectCities = async ([from, to]: [Station | null, Station | null]) => {
    const { setFieldValue, values } = formik
    if (to && to.uic !== values.destination?.uic) toggleFocus(true)
    await setFieldValue('origin', from || null)
    await setFieldValue('destination', to || null)
  }

  const handleSelectDates = async ([from, to]: [Date | null, Date | null]) => {
    const { setFieldValue } = formik
    await setFieldValue('outboundDate', from)
    await setFieldValue('inboundDate', to)
  }

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <FlexContainer container justify='space-between' alignItems='flex-start'>
          <StyledFlex item grow={1}>
            <StyledFlex container alignItems='flex-start' columnGap={8}>
              <CitySelect
                cityFrom={formik.values.origin}
                cityTo={formik.values.destination}
                initialFrom={initialValues.origin}
                initialTo={initialValues.destination}
                onSelectCities={handleSelectCities}
                useHints={useHints}
                autofocus={autofocus}
              />
              <DatepickerContainer>
                <Datepicker
                  initialFrom={
                    initialValues.outboundDate ? new Date(initialValues.outboundDate) : null
                  }
                  initialTo={initialValues.inboundDate ? new Date(initialValues.inboundDate) : null}
                  focus={focusOnDatepicker}
                  clearFocus={() => toggleFocus(false)}
                  onSelectDates={handleSelectDates}
                  useHints={useHints}
                  isHomeScreen={isHomeScreen}
                />
              </DatepickerContainer>
              <PassengersSelectContainer>
                <PassengersSelect
                  passengers={formik.values.passengers}
                  addPassenger={formik.addPassenger}
                  removePassenger={formik.removePassenger}
                />
              </PassengersSelectContainer>
            </StyledFlex>
          </StyledFlex>
          <StyledButton isLoading={formik.isSubmitting} type='submit' disabled={!formik.isValid}>
            {t('find')}
          </StyledButton>
        </FlexContainer>
      </form>
    </Wrapper>
  )
}
