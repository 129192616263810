import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/core'

export const getStations = (state: RootState) => state.stations.entities

export const getSortedStations = createSelector(getStations, stations =>
  /*
   * Spread operator is used for avoid mutating original array using sort method
   * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort}
   * {@link https://stackoverflow.com/questions/9592740/how-can-you-sort-an-array-without-mutating-the-original-array}
   * */
  [...stations].sort((stationA, stationB) => {
    if (stationA.name < stationB.name) return -1
    if (stationA.name > stationB.name) return 1
    return 0
  })
)
