import { transparentize } from 'polished'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`

export const Popper = styled.div<{ width?: string | number }>`
  border: none;
  background-color: ${({ theme }) => transparentize(0.2, theme.palette.primary.MAIN)};
  padding: 4px 8px;
  z-index: 1001;
  color: ${({ theme }) => theme.palette.text.ALTERNATIVE};
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  backdrop-filter: blur(3px);
  border-radius: 4px;
  text-align: center;
  max-width: ${({ width }) =>
    width ? `${typeof width === 'number' ? `${width}px` : width}` : 'auto'};
  animation ${fadeIn} 0.25s linear;
`

export const Arrow = styled.div`
  --tooltipBackground: ${({ theme }) => transparentize(0.25, theme.palette.primary.MAIN)};
  --tooltipBorder: transparent;
`
